import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import {NavLink, Redirect, Route, Switch} from "react-router-dom";
import {signOut} from "../../store/actions/auth";
import {connect, useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {API_URL} from "../../config";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {withSnackbar} from "notistack";
import PersonIcon from '@material-ui/icons/Person';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import PayrollIndex from "../../containers/Payroll/PayrollIndex/PayrollIndex";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ReceiptIcon from '@material-ui/icons/Receipt';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import authAxios from "../../services/authService";
import {changeCompany} from "../../store/actions/settings";
import Loading from "../../containers/Loading/Loading";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import CustomError from "../../helpers/CustomError";
import {handleError} from "../../helpers/errorHandler";
import logo from '../../assets/images/logo.png';
import {COLOR_SECONDARY} from "../../config/colors";
import DocumentsIndex from "../../containers/Documents/DocumentsIndex/DocumentsIndex";
import ProfileIndex from "../../containers/Profile/ProfileIndex/ProfileIndex";

const drawerWidth = 250;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        minHeight: '100vh'
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        background: 'linear-gradient(0deg, rgba(131,49,81,1) 0%, rgba(62,0,28,1) 100%)'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
        textAlign: 'left'
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        position: 'relative'
    },
    container: {
        display: 'flex',
        flex: 1,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    mainContainer: {
        display: 'flex',
        height: '100vh',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'stretch'
    },
    formControl: {
        margin: theme.spacing(2)
    }
}));

const Dashboard = ({ enqueueSnackbar }) => {

    const dispatch = useDispatch();

    const classes = useStyles();

    // State de autenticación
    const authState = useSelector((state) => state.authReducer);
    const {uid, token, modules} = authState;

    console.log('Auth', authState);

    // State de menú
    const [menuAnchor, setMenuAnchor] = useState(null);

    // State de empresa seleccionada
    const [companyId, setCompanyId] = useState('');

    // State de listado de empresas
    const [companies, setCompanies] = useState([]);

    // State de indicador de carga
    const [areCompaniesLoading, setAreCompaniesLoading] = useState(true);

    // State de error
    const [error, setError] = useState('');

    // Función que se ejecuta al cargar la ventana
    useEffect(() => {

        const init = async () => {
            // Cargar empresas de usuario
            await loadCompanies();
        };

        init();

    }, []);

    // Función que carga las empresas del usuario
    const loadCompanies = async () => {

        // Mostrar indicador de carga
        setAreCompaniesLoading(true);

        try {

            const config = {
                params: {
                    uid
                }
            };

            // Hacer request
            const response = await authAxios(token).post(
                `${API_URL}/kiosko/v1/Empleado`,
                null,
                config
            );

            if(response.data.status !== 'Success'){
                throw new CustomError(response.data.message, 'custom');
            }

            // Conseguir respuesta
            let companiesData = response.data.data['compañias'];

            // Actualizar state
            setCompanies(companiesData);
            setCompanyId(companiesData[0].rfc);
            dispatch(changeCompany(companiesData[0].rfc));
        } catch (error) {

            if(error.status === 'custom'){
                enqueueSnackbar(handleError('custom', error.message));
                setError(handleError('custom', error.message));
            } else {
                if(!error.response){
                    enqueueSnackbar(handleError('network', 'No hay conexión a internet.'));
                    setError(handleError('network', 'No hay conexión a internet.'));
                } else if(error.response.data && error.response.data.message){
                    enqueueSnackbar(handleError('custom', error.response.data.message));
                    setError(handleError('custom', error.response.data.message));
                } else {
                    enqueueSnackbar(handleError(error.response.status));
                    setError(handleError(error.response.status));
                }
            }
        }

        // Esconder indicador de carga
        setAreCompaniesLoading(false);
    };

    // Función que cierra la sesión
    const handleLogout = async () => {
        localStorage.removeItem('token');
        localStorage.removeItem('uid');
        localStorage.removeItem('modules');
        dispatch(signOut());
    };

    const handleCompanyChange = (event) => {
        setCompanyId(event.target.value);
        dispatch(changeCompany(event.target.value));
    };

    const renderCompanies = () => {
        return companies.map((company, index) => (<MenuItem value={company.rfc}>{company.nombre}</MenuItem>))
    };

    return (
        <div style={ { backgroundColor: '#e0e0de' } } className={ classes.root }>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar s>
                    <div style={{flexGrow: 1}}>
                        <img src={logo} alt="Prosys" style={{maxWidth: 150}} />
                    </div>
                    <div style={{backgroundColor: 'white', borderRadius: 33, boxShadow: '0 0 5px 0 rgba(0,0,0,0.75)'}}>
                        <IconButton color="primary" onClick={ (event) => setMenuAnchor(event.currentTarget) }>
                            <PersonIcon />
                        </IconButton>
                    </div>
                    <Menu
                        id="simple-menu"
                        anchorEl={menuAnchor}
                        keepMounted
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                        open={Boolean(menuAnchor)}
                        onClose={() => setMenuAnchor(null)}
                    >
                        <MenuItem onClick={ handleLogout }>Cerrar sesión</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="left"
            >
                <div className={classes.toolbar} />
                <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">Empresa</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={companyId}
                        onChange={handleCompanyChange}
                    >
                        { renderCompanies() }
                    </Select>
                </FormControl>
                <Divider />
                <div style={{flex: 1}}>
                <List>
                    <ListItem button component={NavLink} to="/payroll">
                        <ListItemIcon><ReceiptIcon/></ListItemIcon>
                        <ListItemText primary="Nóminas" />
                    </ListItem>
                </List>
                    {
                        (modules !== null && modules.includes('ModDoc')) &&
                        <List>
                            <ListItem button component={NavLink} to="/documents">
                                <ListItemIcon><FileCopyIcon/></ListItemIcon>
                                <ListItemText primary="Documentos" />
                            </ListItem>
                        </List>
                    }
                    <List>
                        <ListItem button component={NavLink} to="/profile">
                            <ListItemIcon><PersonIcon/></ListItemIcon>
                            <ListItemText primary="Perfil" />
                        </ListItem>
                    </List>
                </div>
                <div style={{background: 'linear-gradient(0deg, rgba(131,49,81,1) 0%, rgba(62,0,28,1) 100%)'}}>
                    <p style={{color: 'white', textAlign: 'center', display: 'block'}}>Conoce más en <a href="https://prosys.mx" style={{color: 'white'}}>prosys.mx</a></p>
                </div>
            </Drawer>
            <main className={classes.content}>
                {
                    areCompaniesLoading ?
                        <Loading style={{position: 'absolute'}} /> :
                        error ?
                            <ErrorMessage message={error}/> :
                            <Switch>
                                <Route path="/payroll" exact component={PayrollIndex}/>
                                <Route path="/documents" exact component={DocumentsIndex}/>
                                <Route path="/profile" exact component={ProfileIndex}/>
                                <Redirect from="/" to="/payroll" />
                            </Switch>
                }
            </main>
        </div>
    );
};

export default withSnackbar(Dashboard);
