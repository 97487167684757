import Typography from "@material-ui/core/Typography";
import React from "react";
import WarningIcon from '@material-ui/icons/Warning';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    errorWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '100%'
    },
}));

const ErrorMessage = ({message}) => {

    const classes = useStyles();

    return <div className={classes.errorWrapper}>
        <WarningIcon color="primary" style={{marginRight: 8}}/>
        <Typography>{message}</Typography>
    </div>;

};

export default ErrorMessage;
