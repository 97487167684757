// Acciones
import {LOGIN, LOGOUT, RETRIEVE_TOKEN} from '../actionTypes';

// State inicial del reducer
const authState = {
    isLoading: true,
    uid: null,
    token: null,
    modules: null
};

// State de autenticación
const authReducer = (state = authState, action) => {
    switch(action.type){
        case LOGIN:
            return { ...state, uid: action.payload.uid, token: action.payload.token, modules: action.payload.modules, isLoading: false };
        case LOGOUT:
            return { ...state, uid: null, token: null, modules: null, isLoading: false };
        case RETRIEVE_TOKEN:
            return { ...state, uid: action.payload.uid, token: action.payload.token, modules: action.payload.modules, isLoading: false };
        default:
            return state;
    }
};

export default authReducer;
