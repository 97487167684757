// Acciones
import {LOGIN, LOGOUT, RETRIEVE_TOKEN} from "../actionTypes";

// Acción de login
export const signIn = (uid, token, modules) => ({
    type: LOGIN,
    payload: {
        uid,
        token,
        modules
    }
});

// Acción de logout
export const signOut = () => ({
    type: LOGOUT,
    payload: null
});

// Acción que busca el token al iniciar la aplicación
export const retrieveToken = (uid, token, modules) => ({
    type: RETRIEVE_TOKEN,
    payload: {
        uid,
        token,
        modules
    }
});


