import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import * as moment from 'moment';
import {API_URL} from "../../../config";
import Table from "../../../components/Table/Table";
import {withSnackbar} from "notistack";
import Container from "@material-ui/core/Container";
import {COLOR_PRIMARY} from "../../../config/colors";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from '@material-ui/icons/GetApp';
import Tooltip from "@material-ui/core/Tooltip";
import FilterListIcon from '@material-ui/icons/FilterList';
import FilterMenu from "../../../components/FilterMenu/FilterMenu";
import {useSelector} from "react-redux";
import authAxios from "../../../services/authService";
import CustomError from "../../../helpers/CustomError";
import {handleError} from "../../../helpers/errorHandler";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CodeIcon from '@material-ui/icons/Code';
import {Card, Grid} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginBottom: 30
    },
    toolbar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    action: {
        marginRight: 8
    },
    progressBar: {
        width: '100%'
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        display: 'flex',
        flex: 1,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    id: {
        color: COLOR_PRIMARY,
        fontWeight: 'bold'
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    chip: {
        backgroundColor: COLOR_PRIMARY,
        color: 'white',
        borderRadius: 33,
        padding: 4
    }
}));

const ProfileIndex = ({ history, enqueueSnackbar }) => {

    const classes = useStyles();

    const [profile, setProfile] = useState(null);

    const authState = useSelector((state) => state.authReducer);
    const {uid, token, modules} = authState;

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const init = async () => {
            setIsLoading(true);
            await loadProfile();
            setIsLoading(false);
        };

        init();
    }, []);

    // Función que define los filtros y carga los registros
    const loadProfile = async () => {

        try {

            // Hacer request
            const response = await authAxios(token).post(
                `${API_URL}/kiosko/v1/Empleado`,
                null,
                {
                    params: {
                        uid
                    }
                }
            );

            if(response.data.status !== 'Success'){
                throw new CustomError(response.data.message, 'custom');
            }

            setProfile(response.data.data);

        } catch(error){

            if(!error.response){
                enqueueSnackbar(handleError('network', 'No hay conexión a internet.'));
            } else if(error.status === 'custom'){
                enqueueSnackbar(handleError('custom', error.message));
            } else {
                if(error.response.data && error.response.data.message){
                    enqueueSnackbar(handleError('custom', error.response.data.message));
                } else {
                    enqueueSnackbar(handleError(error.response.status));
                }
            }
        }
    };

    return (
        <>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <div className={classes.root}>
                    {
                        isLoading ?
                            <div></div> :
                            <div>
                                <p style={{fontSize: 24}}>Información <b>Personal</b></p>
                                <Grid container spacing={2}>
                                    <Grid item sm={12} xs={12}>
                                        <Card style={{padding: 16}}>
                                            <p><b>Nombre Completo</b></p>
                                            <p>{profile.nombre}</p>
                                            <Divider/>
                                            <p><b>Dirección</b></p>
                                            <p>{profile.direccion}</p>
                                            <Divider/>
                                            <p><b>Teléfono</b></p>
                                            <p>{profile.telefono}</p>
                                            <Divider/>
                                            <p><b>RFC</b></p>
                                            <p>{profile.rfc}</p>
                                            <Divider/>
                                            <p><b>Número Seguro Social</b></p>
                                            <p>{profile.nss}</p>
                                            <Divider/>
                                            <p><b>CURP</b></p>
                                            <p>{profile.curp}</p>
                                        </Card>
                                    </Grid>
                                </Grid>
                                {
                                    (modules !== null && modules.includes('ModVac')) &&
                                    <div>
                                        <p style={{fontSize: 24, marginTop: 24}}>Mis <b>Vacaciones</b></p>
                                        <Card style={{padding: 16}}>
                                            <p><b>Vacaciones Pagadas</b></p>
                                            <p>{profile.vacacionesPagadas} días</p>
                                            <Divider/>
                                            <p><b>Vacaciones Pendientes</b></p>
                                            <p>{profile.vacacionesPendientes} días</p>
                                        </Card>
                                    </div>

                                }
                            </div>
                    }
                </div>
            </Container>
        </>
    );

};

export default withSnackbar(ProfileIndex);
