import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from "@material-ui/core/Paper";
import {COLOR_PRIMARY, COLOR_SECONDARY} from "../../../config/colors";
import SignInForm from "../../../components/Forms/SignInForm";
import {withSnackbar} from "notistack";
import {NavLink} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: COLOR_PRIMARY
    },
    text: {
        color: 'white',
        textAlign: 'center'
    },
    textLink: {
        color: COLOR_SECONDARY,
        fontWeight: 'bold',
        textDecoration: 'none'
    }
}));

const SignIn = ({ enqueueSnackbar }) => {

    const classes = useStyles();

    /* *** State de formulario *** */

    const formFields = {
        username: '',
        password: ''
    };

    /* *** State de formulario *** */

    // State de indicador de carga
    const [isSubmitting, setIsSubmitting] = useState(false);

    document.body.style.backgroundColor = COLOR_PRIMARY;

    return (
        <Container component="main" maxWidth="xs">
            <Paper className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">Iniciar Sesión</Typography>
                <div className={classes.form} >
                    <SignInForm
                        initialValues={formFields}
                        submitting={isSubmitting}
                        onError={(message) => enqueueSnackbar(message)}
                        onLoadingChange={(loading) => setIsSubmitting(loading)}
                    />
                </div>
            </Paper>
            <div style={{ marginTop: 20 }}>
                <Typography className={classes.text}>¿Olvidaste tu contraseña? <Typography className={classes.textLink} component={NavLink} to="/recover">Recupérala</Typography></Typography>
            </div>
        </Container>
    );
};

export default withSnackbar(SignIn);
