import React from 'react';
import {Form} from "react-final-form";
import {TextField} from "mui-rff";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import validate from "validate.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import createDecorator from "final-form-focus";
import axios from 'axios';
import {API_URL} from "../../config";
import {useDispatch} from "react-redux";
import {signIn} from "../../store/actions/auth";
import CustomError from "../../helpers/CustomError";
import {handleError} from "../../helpers/errorHandler";

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        marginTop: 16
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
}));

const SignInForm = ({ initialValues, onError, onLoadingChange, submitting }) => {

    const dispatch = useDispatch();

    const classes = useStyles();

    const focusOnErrors = createDecorator();

    // Reglas de validación de formulario
    const constraints = {
        username: {
            presence: {
                allowEmpty: false,
                message: 'Debe de llenar este campo'
            }
        },
        password: {
            presence: {
                allowEmpty: false,
                message: 'Debe de llenar este campo'
            },
            length: {
                minimum: 6,
                message: 'La contraseña debe contener almenos 6 caracteres'
            }
        }
    };

    // Función que se ejecuta al enviar formulario
    async function onSubmit(values, form) {

        // Mostrar indicador de carga
        onLoadingChange(true);

        try {

            // Datos a enviar
            const data = {...values};

            // Hacer request
            const response = await axios.post(
                `${API_URL}/kiosko/v1/login`,
                data
            );

            if(response.data.status !== 'Success'){
                throw new CustomError(response.data.message, 'custom');
            }

            const authData = response.data.data;

            // Guardar información de usuario en localStorage y actualizar state de Redux
            localStorage.setItem('uid', authData.uid);
            localStorage.setItem('token', authData.token);

            if(authData.modules) {
                localStorage.setItem('modules', Object.keys(authData.modules).toString());
                dispatch(signIn(authData.uid, authData.token, Object.keys(authData.modules).toString()));
            } else {
                dispatch(signIn(authData.uid, authData.token, null));
            }

            // Esconder indicador de carga
            onLoadingChange(false);

        } catch (error) {

            // Esconder indicador de carga
            onLoadingChange(false);

            if(!error.response){
                onError(handleError('network', 'No hay conexión a internet.'));
            } else if(error.status === 'custom'){
                onError(handleError('custom', error.message));
            } else {
                if(error.response.data && error.response.data.message)
                    onError(handleError('custom', error.response.data.message));
                else
                    onError(handleError(error.response.status));
            }
        }

    }

    // Función que valida el formulario
    async function validateForm(values) {

        // Validar campos
        let valid = validate({...values}, constraints, {fullMessages: false});

        // Si no hay error
        if(!valid)
            return;
        else {

            // Modificar objeto para mostrar errores en formulario
            Object.keys(valid).forEach((key, index) => {
                valid[key] = valid[key][0];
            });

            return valid;
        }
    }

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            decorators={[ focusOnErrors ]}
            validate={validateForm}
            render={({ handleSubmit, values, submitError, form }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <TextField label="Correo electrónico o teléfono" name="username" />
                    <TextField label="Contraseña" name="password" type="password"/>
                    <div className={ classes.buttonWrapper }>
                        <Button fullWidth color="primary" type="submit" variant="contained" disabled={submitting} startIcon={ submitting ? <CircularProgress size={18}/> : null }>Iniciar</Button>
                    </div>
                </form>
            )}
        />
    );
};

export default SignInForm;
