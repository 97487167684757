import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Collapse from "@material-ui/core/Collapse/Collapse";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {TextField} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu/Menu";
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles(theme => ({
    filterMenuWrapper: {
        paddingLeft: 10,
        paddingTop: 10,
        paddingBottom: 10,
        paddingRight: 10,
        backgroundColor: '#FAFAFA'
    },
    filterMenuHeader: {
        marginLeft: 10,
        marginRight: 10,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
    },
    checkboxLabel: {
        textAlign: 'left',
        flex: 1
    }
}));

const FilterMenu = ({ filterAnchor, filtersConfig, onSave, onClear, onClose }) => {

    const classes = useStyles();

    const [filtersData, setFiltersData] = useState(filtersConfig);

    const handleFilterCollapsibleClick = (index) => {
        let newFiltersData = [...filtersData];
        newFiltersData[index].control.open = !newFiltersData[index].control.open;
        setFiltersData(newFiltersData);
    };

    return <div>
        <Menu
            anchorEl={filterAnchor}
            keepMounted
            open={Boolean(filterAnchor)}
            onClose={onClose}
            PaperProps={{
                style: {
                    minWidth: 350,
                },
            }}
        >
            <div className={classes.filterMenuHeader}>
                <Typography variant="h6" style={{flex: 1}}>Filtros</Typography>
                <IconButton aria-label="save" onClick={() => onSave(filtersData)}>
                    <SaveIcon />
                </IconButton>
                <IconButton aria-label="clear" onClick={() => {
                    setFiltersData(filtersConfig);
                    onClear();
                }}>
                    <CloseIcon />
                </IconButton>
            </div>
            {
                filtersData.map((filter, index) => {
                    if (filter.control.type === 'checkbox') {
                        return <div style={{width: '100%'}}>
                            <FormControlLabel
                                style={{marginLeft: 16, marginRight: 8, width: 'calc(100% - 24px)'}}
                                classes={{
                                    label: classes.checkboxLabel
                                }}
                                control={
                                    <Checkbox
                                        checked={filter.value}
                                        onChange={(event) => {
                                            let newFiltersData = [...filtersData];

                                            if (event.target.checked && newFiltersData[index].control.shouldIndeterminate) {
                                                newFiltersData[index].value = null;
                                                newFiltersData[index].control.shouldIndeterminate = false;
                                                setFiltersData(newFiltersData);
                                                return;
                                            }

                                            if (!event.target.checked) {
                                                newFiltersData[index].control.shouldIndeterminate = true;
                                            }

                                            newFiltersData[index].value = event.target.checked;
                                            setFiltersData(newFiltersData);
                                        }}
                                        indeterminate={filter.value === null}
                                    />
                                }
                                label={filter.field.label}
                                labelPlacement="start"
                            />
                        </div>
                    } else {
                        return <>
                            <ListItem button onClick={() => {
                                handleFilterCollapsibleClick(index);
                            }}>
                                <ListItemText primary={ filter.field.label } />
                                {filter.control.open ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={filter.control.open} timeout="auto" unmountOnExit>
                                <div className={classes.filterMenuWrapper}>
                                    {
                                        filter.control.type === 'date' &&
                                        <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'row' }}>
                                            <KeyboardDatePicker
                                                label="Fecha Inicial"
                                                value={filter.value[0]}
                                                onChange={(date) => {
                                                    let newFiltersData = [...filtersData];
                                                    newFiltersData[index].value[0] = date;
                                                    setFiltersData(newFiltersData);
                                                }}
                                                disableFuture={true}
                                                format={'YYYY-MM-DD'}
                                                style={{marginRight: 20}}
                                            />
                                            <KeyboardDatePicker
                                                label="Fecha Final"
                                                value={filter.value[1]}
                                                onChange={(date) => {
                                                    let newFiltersData = [...filtersData];
                                                    newFiltersData[index].value[1] = date;
                                                    setFiltersData(newFiltersData);
                                                }}
                                                disableFuture={true}
                                                format={'YYYY-MM-DD'}
                                            />
                                        </div>
                                    }
                                    {
                                        filter.control.type === 'text' &&
                                        <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'row' }}>
                                            <TextField
                                                label={filter.field.label}
                                                value={filter.value}
                                                fullWidth
                                                onChange={(event) => {
                                                    let newFiltersData = [...filtersData];
                                                    newFiltersData[index].value = event.target.value;
                                                    setFiltersData(newFiltersData);
                                                }}
                                            />
                                        </div>
                                    }
                                    {
                                        filter.control.type === 'select' &&
                                        <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'row' }}>
                                            <FormControl fullWidth>
                                                <InputLabel>{filter.field.label}</InputLabel>
                                                <Select
                                                    value={filter.value}
                                                    onChange={(event) => {
                                                        let newFiltersData = [...filtersData];
                                                        newFiltersData[index].value = event.target.value;
                                                        setFiltersData(newFiltersData);
                                                    }}
                                                >
                                                    {
                                                        filter.control.data.map((item) => {
                                                            return <MenuItem value={item.value}>{item.label}</MenuItem>
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                    }
                                </div>
                            </Collapse>
                        </>
                    }
                })
            }
        </Menu>
    </div>
};

export default FilterMenu;
