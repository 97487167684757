import React, {useEffect} from 'react';
import './App.css';
import {connect, useDispatch, useSelector} from "react-redux";
import {createMuiTheme, ThemeProvider} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import SignIn from "./containers/Auth/SignIn/SignIn";
import Loading from "./containers/Loading/Loading";
import {SnackbarProvider} from "notistack";
import {retrieveToken, signIn} from "./store/actions/auth";
import {COLOR_PRIMARY, COLOR_SECONDARY} from "./config/colors";
import "moment/locale/es";
import moment from "moment";
import PasswordCreate from "./containers/Auth/ChangePassword/ChangePassword";
import RecoverPassword from "./containers/Auth/RecoverPassword/RecoverPassword";
import ConfirmRecoverPassword from "./containers/Auth/ConfirmRecoverPassword/ConfirmRecoverPassword";

function App() {

  const dispatch = useDispatch();

  const authState = useSelector((state) => state.authReducer);
  const {uid, token, modules, isLoading} = authState;

  moment.locale('es');

  useEffect(() => {

    const init = async () => {

      let token = null;
      let uid = null;
      let modules = null;

      try {

        uid = localStorage.getItem('uid');
        token = localStorage.getItem('token');
        modules = localStorage.getItem('modules');

      } catch(error) {

      }

      dispatch(retrieveToken(uid, token, modules));
    };

    init();

  }, []);

  // Tema de Material UI
  const theme = createMuiTheme({
    palette: {
      primary: { main: COLOR_PRIMARY },
      secondary: { main: COLOR_SECONDARY }
    },
    props: {
      MuiTextField: {
        margin: 'normal',
      },
      MuiFormControl: {
        margin: 'normal',
      },
    }
  });

  return <ThemeProvider theme={ theme }>
    <SnackbarProvider maxSnack={3} anchorOrigin={{horizontal: "right", vertical: "bottom"}}>
      <MuiPickersUtilsProvider utils={MomentUtils} locale={'es'}>
        <BrowserRouter>
          {
            isLoading ?
                <Loading />:
                <Switch>
                  {
                    uid && token ?
                        <Route path="/" component={ Dashboard } /> :
                        <>
                          <Route path="/login" exact component={ SignIn } />
                          <Route path="/recover" exact component={ RecoverPassword } />
                          <Route path="/verify" exact component={ ConfirmRecoverPassword } />
                          <Route path="/password" exact component={ PasswordCreate } />
                          <Redirect from="/" to="/login" />
                        </>
                  }
                </Switch>
          }
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    </SnackbarProvider>
  </ThemeProvider>
}

export default App;
