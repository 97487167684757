import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import * as moment from 'moment';
import {API_URL} from "../../../config";
import Table from "../../../components/Table/Table";
import {withSnackbar} from "notistack";
import Container from "@material-ui/core/Container";
import {COLOR_PRIMARY} from "../../../config/colors";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from '@material-ui/icons/GetApp';
import Tooltip from "@material-ui/core/Tooltip";
import FilterListIcon from '@material-ui/icons/FilterList';
import FilterMenu from "../../../components/FilterMenu/FilterMenu";
import {useSelector} from "react-redux";
import authAxios from "../../../services/authService";
import CustomError from "../../../helpers/CustomError";
import {handleError} from "../../../helpers/errorHandler";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CodeIcon from '@material-ui/icons/Code';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginBottom: 30
    },
    toolbar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    action: {
        marginRight: 8
    },
    progressBar: {
        width: '100%'
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        display: 'flex',
        flex: 1,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    id: {
        color: COLOR_PRIMARY,
        fontWeight: 'bold'
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    chip: {
        backgroundColor: COLOR_PRIMARY,
        color: 'white',
        borderRadius: 33,
        padding: 4
    }
}));

const PayrollIndex = ({ history, enqueueSnackbar }) => {

    const classes = useStyles();

    const authState = useSelector((state) => state.authReducer);
    const {uid, token} = authState;

    const settingsState = useSelector((state) => state.settingsReducer);
    const {company} = settingsState;

    // State de tabla
    const [selected, setSelected] = useState([]);

    const [filterAnchor, setFilterAnchor] = useState(null);

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(false);

    let [columnObject] = useState([
        {
            title: 'Total',
            field: 'total_Neto',
            filtering: false,
            render: rowData => `$${rowData.total_Neto.toFixed(2)}`
        },
        {
            title: 'Efectivo',
            field: 'total',
            filtering: false,
            render: rowData => `$${rowData.total.toFixed(2)}`
        },
        {
            title: 'Despensa',
            field: 'total_Despensa',
            filtering: false,
            render: rowData => `$${rowData.total_Despensa.toFixed(2)}`
        },
        {
            title: 'Fecha de Pago',
            field: 'fecha_pago',
            filtering: false,
            defaultSort: 'desc',
            render: rowData => {
                return moment(rowData.fecha_pago).format('D MMM YYYY');
            }
        },
        {
            title: 'Acciones',
            field: 'pdf_key',
            filtering: false,
            sorting: false,
            export: false,
            render: rowData => <>
                <Tooltip title="Descargar PDF">
                    <IconButton onClick={() => handleFileDownload(rowData.uuid, rowData.bucket, rowData.pdf_key, 'pdf')}>
                        <PictureAsPdfIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Descargar XML">
                    <IconButton onClick={() => handleFileDownload(rowData.uuid, rowData.bucket, rowData.xml_key, 'xml')}>
                        <CodeIcon />
                    </IconButton>
                </Tooltip>
            </>
        }
    ]);

    const defaultFiltersData = [
        {
            field: {
                column: 'fecha_pago',
                label: 'Fecha de Pago'
            },
            value: [null, null],
            control: {
                open: false,
                type: 'date',
            }
        }
    ];

    const [filtersData, setFiltersData] = useState(defaultFiltersData);

    const tableRef = useRef(null);

    useEffect(() => {
        tableRef.current.onQueryChange();
    }, [company]);

    // Función que define los filtros y carga los registros
    const loadTableData = (query) => {

        return new Promise(async (resolve, reject) => {

            // Mostrar indicador de carga
            setIsLoading(true);

            try {

                // Hacer request
                const response = await authAxios(token).post(
                    `${API_URL}/kiosko/v1/acumulados`,
                    {
                        uid,
                        rfc_empresa: company,
                        sort: query.orderDirection === 'asc' ? query.orderBy.field : `-${query.orderBy.field}`,
                        keyword: query.search || null,
                        fecha_inicial: filtersData[0].value[0],
                        fecha_final: filtersData[0].value[1]
                    },
                    {
                        params: {
                            PageNumber: query.page + 1,
                            PageSize: query.pageSize
                        }
                    }
                );

                if(response.data.status !== 'Success'){
                    throw new CustomError(response.data.message, 'custom');
                }

                resolve({data: response.data.data.data, page: response.data.data.pageNumber - 1, totalCount: response.data.data.totalItems});

            } catch(error){

                if(!error.response){
                    enqueueSnackbar(handleError('network', 'No hay conexión a internet.'));
                } else if(error.status === 'custom'){
                    enqueueSnackbar(handleError('custom', error.message));
                } else {
                    if(error.response.data && error.response.data.message){
                        enqueueSnackbar(handleError('custom', error.response.data.message));
                    } else {
                        enqueueSnackbar(handleError(error.response.status));
                    }
                }
            }

            // Esconder indicador de carga
            setIsLoading(false);

        });
    };

    const handleFileDownload = async (uuid, bucket, key, type) => {

        try {
            const response = await authAxios(token).post(
                `${API_URL}/kiosko/v1/Descarga`,
                {
                    uuid,
                    plataforma: 1,
                    tipo_archivo: type
                }
            );

            if(response.data.status !== 'success'){
                throw new CustomError(response.data.message, 'custom');
            }

        } catch(error){
            console.log(error);
        }

        window.open(`https://s3.amazonaws.com/${bucket}/${key}`, '_blank');
    };

    return (
        <>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <div className={classes.root}>
                    <Table
                        title="Nómina"
                        tableRef={tableRef}
                        loading={isLoading}
                        columns={columnObject}
                        loadTableData={(query) => loadTableData(query)}
                        selected={selected}
                        canCreate={false}
                        canEdit={false}
                        canDelete={false}
                        canFilter={false}
                        onSelect={(rows) => setSelected(rows)}
                        customActions={[
                            {
                                icon: FilterListIcon,
                                tooltip: 'Filtrar',
                                isFreeAction: true,
                                onClick: async (event) => {
                                    setFilterAnchor(event.currentTarget);
                                }
                            }
                        ]}
                    />
                    <FilterMenu
                        filtersConfig={defaultFiltersData}
                        filterAnchor={filterAnchor}
                        onClose={() => setFilterAnchor(null)}
                        onSave={(data) => {
                            setFiltersData(data);
                            tableRef.current.onPageChange(null, 0);
                            setFilterAnchor(null);
                        }}
                        onClear={() => setFiltersData(defaultFiltersData)}
                    />
                </div>
            </Container>
        </>
    );

};

export default withSnackbar(PayrollIndex);
