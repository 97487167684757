// Acciones

// State inicial del reducer
import {CHANGE_COMPANY} from "../actionTypes";

const settingsState = {
    company: null
};

// State de autenticación
const settingsReducer = (state = settingsState, action) => {
    switch(action.type){
        case CHANGE_COMPANY:
            return { ...state, company: action.payload };
        default:
            return state;
    }
};

export default settingsReducer;
