
export const handleError = (code, message = '') => {

    switch(code) {
        case 400:
            return 'La información enviada fue incorrecta.';
        case 401:
            return 'Necesita autenticarse.';
        case 403:
            return 'Permiso denegado.';
        case 404:
            return 'No se encontró el registro buscado.';
        case 408:
            return 'El tiempo de la operación expiró.';
        case 429:
            return 'Demasiadas peticiones realizadas.';
        case 'network':
            return message;
        case 'custom':
            return message;
        default:
            return 'Ocurrió un error desconocido.';
    }
};
